import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Strong, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Sunny Horizon Hotel
			</title>
			<meta name={"description"} content={"Відчуйте повний спектр розкоші та комфорту"} />
			<meta property={"og:title"} content={"Sunny Horizon Hotel"} />
			<meta property={"og:description"} content={"Відчуйте повний спектр розкоші та комфорту"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11:56:00.501Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11:56:00.501Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11:56:00.501Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11:56:00.501Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11:56:00.501Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11:56:00.501Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11:56:00.501Z"} />
		</Helmet>
		<Components.Header12>
			<Override slot="link1" />
			<Override slot="link3">
				Sunny Horizon Hotel
			</Override>
		</Components.Header12>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-3">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="65%"
				align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				justify-content="center"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					height="auto"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						min-height="100%"
						src="https://uploads.quarkly.io/6671690efdcae00021e38570/images/1-3.jpg?v=2024-06-18T11:56:00.464Z"
						object-fit="cover"
						left={0}
						top={0}
						right={0}
						position="absolute"
						display="block"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/1-3.jpg?v=2024-06-18T11%3A56%3A00.464Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/1-3.jpg?v=2024-06-18T11%3A56%3A00.464Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/1-3.jpg?v=2024-06-18T11%3A56%3A00.464Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/1-3.jpg?v=2024-06-18T11%3A56%3A00.464Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/1-3.jpg?v=2024-06-18T11%3A56%3A00.464Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/1-3.jpg?v=2024-06-18T11%3A56%3A00.464Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/1-3.jpg?v=2024-06-18T11%3A56%3A00.464Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
			>
				<Box
					transform="translateY(0px)"
					width="100%"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
					padding="0px 0px 65% 0px"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						bottom={0}
						src="https://uploads.quarkly.io/6671690efdcae00021e38570/images/1-2.jpg?v=2024-06-18T11:56:00.463Z"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						display="block"
						top={0}
						srcSet="https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/1-2.jpg?v=2024-06-18T11%3A56%3A00.463Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/1-2.jpg?v=2024-06-18T11%3A56%3A00.463Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/1-2.jpg?v=2024-06-18T11%3A56%3A00.463Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/1-2.jpg?v=2024-06-18T11%3A56%3A00.463Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/1-2.jpg?v=2024-06-18T11%3A56%3A00.463Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/1-2.jpg?v=2024-06-18T11%3A56%3A00.463Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/1-2.jpg?v=2024-06-18T11%3A56%3A00.463Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text margin="2rem 0px 0px 0px" font="--headline1">
					Контакти
				</Text>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					<Strong>
						Адреса:  Zatyshna St, 11, Odesa, Odesa Oblast, 65012
					</Strong>
				</Text>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					<Strong>
						Номер телефону:  0675173753
					</Strong>
				</Text>
			</Box>
		</Section>
		<Components.Footer12>
			<Override slot="link1" />
		</Components.Footer12>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});